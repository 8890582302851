import styled from "styled-components";

export const MainBox = styled.div`
  display: flex;
  justify-content: center;
  min-height: 914px;
  max-width: 100%;
  background-color: white;
  font-family: "SF Pro Text";
  font-style: normal;
  position: relative;
  @media screen and (min-width: 901px) {
    .background-image {
      position: absolute;
      background-image: url("/images/NewMain.svg");
      background-repeat: no-repeat;
      width: 100%;
      height:100%;
      bottom: 0;
     
   
      background-position: 50% 400px;
    }
  .hero-content {
    position: absolute;
    top: 13vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    max-width: 1440px;
    max-height: 813px;
    padding: 32px;
    text-align: center;

    &__sertification {
      font-size: 16px !important;
    }
    h1 {
      font-weight: 800;
      font-size: 64px;
      line-height: 100%;
      letter-spacing: -0.2px;
      margin: 0 0 10px 0;
      color: #121212;
      background-image: linear-gradient(90deg, #121212 0%, #8f8f8f 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      span {
        color: #ff931e;
      }
    }
    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      max-width: 600px;
      letter-spacing: -0.5px;
      color: #4d4d4d;
      margin: 0;
      text-align: center;
      &:nth-child(4) {
        margin-bottom: 12px;
      }
    }

    p[data-link="link"] {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.5px;
      color: #ff931e;
      margin-top: 10px;
    }
    &__button {
      display: flex;
      justify-content: space-between;
      gap: 12px;
      align-items: center;
      width: 35%;
      margin: 12px 0px;
      .mailLink {
        p {
          color: #ff931e;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: -0.5px;
          margin: 0;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .hero-app-links {
    display: flex;
    justify-content: flex-start;
    border-radius: 16px;
    width: 100%;
    max-width: 547px;
    padding: 32px;
    gap: 24px;
    img {
      width: 150px;
      height: 47px;
    }
  }
  .mailLink {
    cursor: pointer;
    text-decoration: none;
  }

  .call-to-action {
    background: rgba(255, 147, 30, 0.8);
    backdrop-filter: blur(5.5px);
    border-radius: 16px;
    max-width: 547px;
    padding: 20px;
    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.5px;
      color: #4d4d4d;
      margin: 0;
    }
    p[data-link="link"] {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.5px;
      color: #121212;
      margin-top: 0px;
    }
  }

  .hero-right-image {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .hero-right-image > img {
    width: 70vw;
  }

  @media screen and (max-width: 900px) {
    display: flex;
    justify-content: center;
    min-height: 600px;
    max-width: 100%;
    background-color: black;
    font-family: "SF Pro Text";
    font-style: normal;
    .background-image {
      position: absolute;
      background-image: url("/images/world_main.svg");
      background-repeat: no-repeat;
      width: 100%;
      height: 70%;
      bottom: 0;
      -webkit-background-size: contain;
      background-size: contain;
      background-position: bottom;
      -webkit-background-position: bottom;
    }
    .hero-content {
      padding: 16px;
      margin: 0;
      width: 95%;
      h1 {
        font-size: 24px;
        line-height: 29px;
      }
      p {
        font-size: 12px;
        line-height: 18px;
      }
      &__button {
        width: 60%;
        min-width: fit-content;
      }
    }

    .hero-right-image > img {
      width: 100%;
    }
  }
`;
