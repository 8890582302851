import { Grid } from "@mui/material";
import React from "react";
import { MainBox } from "./style";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LoginBtn } from "../Navbar/style";
import { t } from "i18next";
import { AppstoreLogo, GoogleLogo, LogoBlocks, LogoImg } from "../Footer/style";

const MainHeroSection = () => {
  const { t } = useTranslation();
  return (
    <MainBox>
      <Grid container>
        <div className="background-image"></div>
        <Grid item xs container direction="column" justifyContent="center">
          <Grid
            item
            xs={1}
            md={1}
            lg={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <div className="hero-content">
              <h1>
                <Trans i18nKey="mainPage.title" />
              </h1>

              <p>{t("mainPage.description.general")}</p>
              <div className="hero-content__button">
                <Link to={"/services"} className={"mailLink"}>
                  <p data-link="link">{t("mainPage.herolink")}</p>
                </Link>
                <Link
                  to={"https://platform.digidoe.com/"}
                  className={"mailLink"}
                  target={"_blank"}
                >
                  <LoginBtn>{t("mainPage.login")}</LoginBtn>
                </Link>
              </div>
              <div className="hero-content__button"></div>
            </div>
          </Grid>

          {/*<Grid item xs={1} md={1} lg={1} display="flex" justifyContent="center" alignItems="flexStart" >*/}
          {/*  /!*<LogoBlocks>*!/*/}
          {/*  <div className="hero-app-links">*/}
          {/*    <Link to={"https://apps.apple.com/gb/app/digidoe-finance-solutions/id6443841487"} target="_blank" className={"link"}>*/}
          {/*      <LogoImg src="images/AppStoreLogo.svg" alt="news-single" />*/}
          {/*    </Link>*/}
          {/*    <Link to={"https://play.google.com/store/apps/details?id=com.digidoe.businessbanking&pli=1"} target="_blank" className={"link"}>*/}
          {/*      <LogoImg src="images/GooglePlayLogo.svg" alt="news-single"  />*/}
          {/*    </Link>*/}
          {/*  </div>*/}
          {/*</Grid>*/}
        </Grid>
      </Grid>
    </MainBox>
  );
};

export default MainHeroSection;
